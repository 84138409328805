/* BACKGROUND OPACITY */
@mixin color-opacity($property, $color, $opacity) {
		$property: $color;
		$property: rgba($color, $opacity);
}

/* BREAKPOINTS */
@mixin breakpoint($point_max:"", $point_min:"") {
	@if $point_max {
		@if $point_max == desktop {
			@media only screen and (max-width: $desktop) { @content ; }
		} @else if $point_max == tablet_l {
			@media only screen and (max-width: $tablet_l) { @content ; }
		} @else if $point_max == tablet_p {
			@media only screen and (max-width: $tablet_p) { @content ; }
		} @else if $point_max == smartphone {
			@media only screen and (max-width: $smartphone)  { @content ; }
		} @else if $point_max == smartphone_p {
			@media only screen and (max-width: $smartphone_p)  { @content ; }
		} @else {
			@media only screen and (max-width: $point_max) { @content ; }
		}
	}
	@if $point_min {
		@if $point_min == desktop {
			@media only screen and (min-width: $desktop) { @content ; }
		} @else if $point_min == tablet_l {
			@media only screen and (min-width: $tablet_l) { @content ; }
		} @else if $point_min == tablet_p {
			@media only screen and (min-width: $tablet_p) { @content ; }
		} @else if $point_min == smartphone {
			@media only screen and (min-width: $smartphone)  { @content ; }
		} @else if $point_min == smartphone_p {
			@media only screen and (min-width: $smartphone_p)  { @content ; }
		} @else {
			@media only screen and (min-width: $point_min) { @content ; }
		}
	}
}


/* FLUID FONT SIZE */
@mixin fluid-font-size($min-font-size, $max-font-size) {
	$u1: unit($min-body-width);
	$u2: unit($grid-width);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {

			font-size: $min-font-size;
			@media screen and (min-width: $min-body-width) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-body-width}) / #{strip-unit($grid-width - $min-body-width)}));
			}

			@media screen and (min-width: $grid-width) {
				font-size: $max-font-size;
			}
		}
	}
}


/* FONT FACE */
@mixin font-face($font-family, $file-path, $font-weight, $font-style) {
	@font-face {
		font-family: $font-family;
			src: url('#{$file-path}.woff2') format('woff2'),
					 url('#{$file-path}.woff') format('woff'),
					 url('#{$file-path}.ttf') format('truetype');
		font-weight: $font-weight;
		font-style: $font-style;
		font-display: swap;
	}
}


/* IMAGE URL */
@function image-url($url) {
	@return url($image-path + $url);
}

/* HOVERS */
/* Create pseduo-classes for `:hover`, `:active`, and `:focus`. */
/* @usage - `@include hover` */
@mixin hover {
	&:hover,
	&:active,
	&:focus { @content; }
}


/* OPACITY */
@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;

	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$opacity-ie)";
	filter: alpha(opacity=$opacity-ie);
	-moz-opacity: $opacity;
	-khtml-opacity: $opacity;
	opacity: $opacity;
}


/* PX TO REM */
@mixin rem($property, $values) {
	$px : ();
	$rem: ();

	@each $value in $values {

		@if $value == 0 or $value == auto {
			$px : append($px, $value);
			$rem: append($rem, $value);
		}

		@else {
			$unit: unit($value);
			$val: parseInt($value);

			@if $unit == "px" {
				$px : append($px,  $value);
				$rem: append($rem, ($val / 10 + rem));
			}

			@if $unit == "rem" {
				$px : append($px,  ($val * 10 + px));
				$rem: append($rem, $value);
			}
		}
	}

	@if $px == $rem {
		#{$property}: $px;
	} @else {
		#{$property}: $px;
		#{$property}: $rem;
	}
}


/* RETINA IMAGES */
@mixin retina-image($filename, $background-size, $extension: png, $retina-filename: null, $retina-suffix: _2x, $asset-pipeline: $asset-pipeline) {
	@if $asset-pipeline {
		background-image: image-url("#{$filename}.#{$extension}");
	} @else {
		background-image:       url("#{$filename}.#{$extension}");
	}

	@include hidpi {
		@if $asset-pipeline {
			@if $retina-filename {
				background-image: image-url("#{$retina-filename}.#{$extension}");
			} @else {
				background-image: image-url("#{$filename}#{$retina-suffix}.#{$extension}");
			}
		} @else {
			@if $retina-filename {
				background-image: url("#{$retina-filename}.#{$extension}");
			} @else {
				background-image: url("#{$filename}#{$retina-suffix}.#{$extension}");
			}
		}

		background-size: $background-size;
	}
}
