/* IMPORTANT: CHANGE THIS FILE ONLY IN FLOATWORK_ TEMPLATE */

// PRIMARY TEXT COLOR (IDEALLY DARK COLOR)
$color-primary: #323643;
// CI COLOR
$color-secondary: #fff;
// SECOND CI COLOR
$color-tertiary: #3AA;


// LINK COLORS
$color-link: #323742;
$color-link-hover: #1d1d1b;
$color-error: #e00;


// BASE COLORS
$white: #FFF;
$black: #000;
$lightblack: #323643;
$anotherlightblack: #3F4456;
$yellow: #f0e97f;
$blue: #7790E8;
$violetblue: #A7B8F8;
$onemoreblue: #889Dea;
$lightblue: #65B5EA;
$lighterlightblue: #90C5E8;
$anotherlightblue: #4CA1D9;
$darklightblue: #2C83BC;
$darkblue: #1e3353;
$darkmagenta: #151826;
$cyan: #93E998;
$darkcyan: #7ab484;
$anotherdarkgrey: #222222;
$lightmediumgrey: #9ea09f;
$mediumgrey: #9E9FA0;
$mediumdarkgrey: #60696b;
$darkgrey: #1d1d1b;
$lightgrey: #F5F3F3;
$lessmediumgrey: #D8D8D8;
$darkyellow: #C4BD1C;
$orange: #FF931E;
$lightorange: #fda15f;
$darkorange: #d79652;
$red: #FE8270;
$darkred: #E50201;

// $white: #FFF
// $black: #000
// $yellow: #ccc
// $blue: #444
// $darkmagenta: #777
// $cyan: #555
// $darkcyan: #888
// $anotherdarkgrey: #888
// $darkgrey: #999
// $lightgrey: #f8f7f9
// $yellow: #999
// $orange: #666
// $red: #888


.darkcyan_color.active, .darkcyan_color:hover {
	color: $cyan;

	.cyan_bg & {
		color: $darkcyan; } }

.darkyellow_color.active, .darkyellow_color:hover {
	color: $yellow;

	.yellow_bg & {
		color: $darkyellow; } }

$colors: ('primary' $color-primary), ('secondary' $color-secondary), ('tertiary' $color-tertiary), ('link' $color-link), ('link-hover' $color-link-hover), ('error' $color-error), ('white' $white), ('black' $black), ('lightblack' $lightblack), ('lightblue' $lightblue), ('lightgrey' $lightgrey), ('blue', $blue), ('darkmagenta', $darkmagenta), ('darkcyan', $darkcyan), ('cyan', $cyan), ('anotherdarkgrey', $anotherdarkgrey), ('lightgrey', $lightgrey), ('darkgrey', $darkgrey), ('mediumgrey', $mediumgrey), ('lessmediumgrey', $lessmediumgrey), ('darkblue', $darkblue), ('yellow', $yellow), ('darkyellow', $darkyellow), ('orange', $orange), ('lightorange', $lightorange), ('red', $red), ('anotherlightblue', $anotherlightblue), ('mediumdarkgrey', $mediumdarkgrey), ('onemoreblue', $onemoreblue), ('lightmediumgrey', $lightmediumgrey), ('darkred', $darkred), ( 'violetblue', $violetblue ), ('darklightblue', $darklightblue), ('lighterlightblue', $lighterlightblue), ('anotherlightblack', $anotherlightblack);

@each $color in $colors {
	$current-selector: nth($color, 1);
	$current-color: nth($color, 2);

	body.#{$current-selector} {
		.wpforms-page-indicator-page.active .wpforms-page-indicator-page-number {
			background-color: ($current-color) !important; }

		div.wpforms-container-full .wpforms-form .wpforms-field-html * {
			color: ($current-color); } }

	.#{$current-selector}_color {
		color: ($current-color);

		.contrast &, &.contrast {
			color: $black; } }

	.#{$current-selector}_bg, .#{$current-selector}_bg.cover {
		background-color: ($current-color);

		.contrast &, &.contrast {
			background: $white; } }

	.#{$current-selector}_lightgrey_bg {
		background: linear-gradient(90deg, ($current-color) 50%, $lightgrey 50%); }

	.#{$current-selector}_border {
		border: 1px solid ($current-color);

		.contrast &, &.contrast {
			border-color: $black; }

		.section_kontakt &, &.workshop_dropdown {
			border-radius: 2px; }

		&.workshop_dropdown {
			border-width: 1px;

			&.border_thick {
				border-width: 2px; } } }

	.#{$current-selector} .filter_layout input[type="checkbox"] + label:before {
		border-color: ($current-color); }

	body .filter_layout input[type="checkbox"].#{$current-selector} + label:before {
		border-color: ($current-color); }

	.filter_layout input[type="checkbox"].#{$current-selector} + label:hover:before {
		background-color: ($current-color); }

	#main a {
		&.#{$current-selector}_color {
			color: ($current-color); } }

	.main_menu > li.#{$current-selector}_bg > a:after {
		background: ($current-color); }

	.main_menu > li {
		&.#{$current-selector}_bg {
			> .sub-menu {
				background: ($current-color);

				&:before {
					background: ($current-color); }

				li:hover {
					position: relative;

					a {
						position: relative; } }

				li.menu-item-has-children:hover:after {
					content: '';
					position: absolute;
					left: 100%;
					border: solid transparent;
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-color: rgba(136, 183, 213, 0);
					border-left-color: ($current-color);
					border-width: 30px;
					z-index: 2;
					transform: translateY(-20%); } }

			.sub-menu .sub-menu {
				li {
					a {
						text-transform: none;
						color: ($current-color); }

					&:hover {
						a {
							color: $lightblack;
							background: ($current-color); } } }

				.sub-menu {
					background: ($current-color); } } }

		&.#{$current-selector}_color {
			> .sub-menu {
				> li > a {
					color: $lightblack;
					transition: color .3s; } }

			&.mouseenter {
				> .sub-menu > li > a {
					color: ($current-color); } }

			> .sub-menu, &.mouseenter > .sub-menu {
				> li {
					&:hover {
						> a {
							color: $lightblack;
							background: ($current-color); } } } }

			.sub-menu .sub-menu {

				@media only screen and (max-width: 1050px) {
					background: ($current-color); }

				.sub-menu {
					a {
						color: ($current-color);
						transition: color .3s; }

					li:hover a {
						color: $lightblack;
						background: ($current-color); } } }

			&.lessmediumgrey_color {
				> .sub-menu {
					> li > a {
						color: $lessmediumgrey;
						transition: color .3s; } }

				&.mouseenter {
					> .sub-menu > li > a {
						color: $lessmediumgrey; } }

				> .sub-menu, &.mouseenter > .sub-menu {
					> li {
						&:hover {
							> a {
								color: $lightblack;
								background: $lessmediumgrey; } } } } } } } }



input, textarea, button, select, label, a {
	-webkit-tap-highlight-color: rgba(0,0,0,0); }

a {
	color: inherit; }

.lightgrey_bg {
	.yellow_color {
		color: $darkyellow; } }


.yellow_lightblack_bg {
	background: linear-gradient(90deg, $yellow 50%, $lightblack 50%); }

.lightblack_yellow_bg {
	background: linear-gradient(90deg, $lightblack 50%, $yellow 50%); }

.lightblack_cyan_bg {
	background: linear-gradient(90deg, $lightblack 50%, $cyan 50%); }

.lightblack_lightblue_bg {
	background: linear-gradient(90deg, $lightblack 50%, $lightblue 50%); }

.lightblack_blue_bg {
	background: linear-gradient(90deg, $lightblack 50%, $blue 50%); }

.lightblue_lightblack_bg {
	background: linear-gradient(90deg, $lightblue 50%, $lightblack 50%); }

.lightblack_yellow_horizont_bg {
	background: linear-gradient(180deg, $lightblack 50%, $yellow 50%);

	@media only screen and (max-width: 768px) {
		background: linear-gradient(180deg, $lightblack 40%, $yellow 0%); }

	@media only screen and (max-width: 767px) {
		background: linear-gradient(180deg, $lightblack 30%, $yellow 0%); } }
