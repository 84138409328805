/* IMPORTANT: CHANGE THIS FILE ONLY IN FLOATWORK_ TEMPLATE */

/* FONTS */
$font-primary:																	'HelveticaNeue-Roman', sans-serif;
$font-secondary:																'HelveticaNeue-Bold', serif;
$font-tertiary:																	'HelveticaNeue-Condensed-Bold', sans-serif;

/* PATHS */
$image_path:																		'../../../images/';

/* BREAKPOINTS */
$min-body-width:																320px;
$smartphone_p:																	480px;
$smartphone:																		767px;
$tablet_p:																			1023px;
$tablet_l:																			1024px;
$desktop:																				1280px;
$sizes: ('xl', 'desktop'), ('l', 'tablet_l'), ('m', 'tablet_p'), ('s', 'smartphone'), ('xs', 'smartphone_p');

/* FONT SIZES */
$html-font-size:																62.5%;

$base-font-family:															$font-primary;
$base-font-size:																16px;
$base-line-height:															23px;
$base-text-transform:														none;
$base-letter-spacing:														0.023em;
$base-font-weight:															300;

$h1-font-family:																$font-secondary;
$h1-font-size:																	42px;
$h1-line-height:																43px;
$h1-text-transform:															none;
$h1-letter-spacing:															0em;
$h1-font-weight:																300;

$h2-font-family:																$font-secondary;
$h2-font-size:																	29px;
$h2-line-height:																31px;
$h2-text-transform:															none;
$h2-letter-spacing:															0.03rem;
$h2-font-weight:																300;

$h3-font-family:																$font-secondary;
$h3-font-size:																	34px;
$h3-line-height:																34px;
$h3-text-transform:															none;
$h3-letter-spacing:															0.02em;
$h3-font-weight:																300;

$h4-font-family:																$font-secondary;
$h4-font-size:																	19px;
$h4-line-height:																27px;
$h4-text-transform:															none;
$h4-letter-spacing:															-0.02em;
$h4-font-weight:																300;

$h5-font-family:																$font-secondary;
$h5-font-size:																	19px;
$h5-line-height:																21px;
$h5-text-transform:															uppercase;
$h5-letter-spacing:															0em;
$h5-font-weight:																300;

$h6-font-family:																$font-secondary;
$h6-font-size:																	13px;
$h6-line-height:																19px;
$h6-text-transform:															uppercase;
$h6-letter-spacing:															0em;
$h6-font-weight:																300;

$header-menu-font-family:												$font-secondary;
$header-menu-font-size:													16px;
$header-menu-line-height:												20px;
$header-menu-text-transform:										uppercase;
$header-menu-letter-spacing:										0.03em;
$header-menu-font-weight:												300;

$header-submenu-font-family:										$font-secondary;
$header-submenu-font-size:											17px;
$header-submenu-line-height:										24px;
$header-submenu-text-transform:									none;
$header-submenu-letter-spacing:									0em;
$header-submenu-font-weight:										300;

$header-menu-mobile-font-family:								$font-secondary;
$header-menu-mobile-font-size:									16px;
$header-menu-mobile-line-height:								20px;
$header-menu-mobile-text-transform:							uppercase;
$header-menu-mobile-letter-spacing:							0em;
$header-menu-mobile-font-weight:								300;

$header-submenu-mobile-font-family:							$font-secondary;
$header-submenu-mobile-font-size:								16px;
$header-submenu-mobile-line-height:							20px;
$header-submenu-mobile-text-transform:					uppercase;
$header-submenu-mobile-letter-spacing:					0em;
$header-submenu-mobile-font-weight:							300;

$footer-menu-font-family:												$font-secondary;
$footer-menu-font-size:													17px;
$footer-menu-line-height:												23px;
$footer-menu-text-transform:										uppercase;
$footer-menu-letter-spacing:										0.04em;
$footer-menu-font-weight:												300;

$footer-submenu-font-family:										$font-primary;
$footer-submenu-font-size:											12px;
$footer-submenu-line-height:										14px;
$footer-submenu-text-transform:									none;
$footer-submenu-letter-spacing:									0em;
$footer-submenu-font-weight:										300;

$list-font-family:															$font-primary;
$list-font-size:																$base-font-size;
$list-line-height:															$base-line-height;
$list-text-transform:														$base-text-transform;
$list-letter-spacing:														$base-letter-spacing;
$list-font-weight:															$base-font-weight;

$input-field-font-family:												$font-primary;
$input-field-font-size:													20px;
$input-field-line-height:												26px;
$input-field-text-transform:										$base-text-transform;
$input-field-letter-spacing:										0.15em;
$input-field-font-weight:												400;

$button-font-family:														$font-secondary;
$button-font-size:															$base-font-size;
$button-line-height:														$base-line-height;
$button-text-transform:													$base-text-transform;
$button-letter-spacing:													$base-letter-spacing;
$button-font-weight:														$base-font-weight;

$submit-button-font-family:											$font-secondary;
$submit-button-font-size:												$base-font-size;
$submit-button-line-height:											$base-line-height;
$submit-button-text-transform:									$base-text-transform;
$submit-button-letter-spacing:									$base-letter-spacing;
$submit-button-font-weight:											$base-font-weight;

$select-font-family:														$font-primary;
$select-font-size:															$base-font-size;
$select-line-height:														$base-line-height;
$select-text-transform:													$base-text-transform;
$select-letter-spacing:													$base-letter-spacing;
$select-font-weight:														$base-font-weight;

$big-font-family:																$font-primary;
$big-font-size:																	20px;
$big-line-height:																26px;
$big-text-transform:														$base-text-transform;
$big-letter-spacing:														-0.01em;
$big-font-weight:																$base-font-weight;

$medium-font-family:														$font-primary;
$medium-font-size:															24px;
$medium-line-height:														38px;
$medium-text-transform:													uppercase;
$medium-letter-spacing:													-0.01em;
$medium-font-weight:														$base-font-weight;

$small-font-family:															$font-primary;
$small-font-size:																$base-font-size*0.8;
$small-line-height:															$base-line-height*0.8;
$small-text-transform:													$base-text-transform;
$small-letter-spacing:													$base-letter-spacing;
$small-font-weight:															$base-font-weight;


/* GRID */
$grid-width:																		1180px;
$gutter-width:																	20px;
$outer-gutter-width:														45px;

/* DESKTOP */
$header-height:																	98px;
$header-height-scroll:													58px;
$logo-width:																		315px;
$logo-height:																		24px;

/* MOBILE */
$mobile-logo-width:															160px;
$mobile-menu-button-outer-padding-ipad:					20px;
$mobile-menu-button-outer-padding-phone:				20px;
$mobile-bar-height:															70px;
$mobile-menu-button-width:											35px;
$mobile-menu-bar-height:												6px;
$mobile-menu-bar-gutter-vertical:								7px;

/* FORMS */
$input-height:																	40px;
$select-height:																	35px;
$search-height:																	60px;
$checkbox-size:																	21px;
$radio-btn-size:																24px;


/* SLIDESHOW */
$slider-arrow-color:														#fff;
$slider-arrow-size:															20px;
$slider-arrow-position:													-60px;
$slider-dot-color:															#fff;
$slider-dot-color-active:												#fff;
$slider-dot-size:																10px;
$slider-prev-character:													'\f053';
$slider-next-character:													'\f054';
$slider-opacity-default:												0.75;
$slider-opacity-on-hover:												1;
$slider-opacity-not-active:											0.25;
